<template>
  <div class="compliance">
    <complianceHero />
    <div class="container">
      <complianceAppeal />
      <complianceEthics />
      <complianceAbout />
      <complianceSpeak />
      <compliance-form id="#compliance-form" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplianceService",
  components: {
    ComplianceHero: () => import("@/components/compliance/ComplianceHero.vue"),
    ComplianceAppeal: () =>
      import("@/components/compliance/ComplianceAppeal.vue"),
    ComplianceEthics: () =>
      import("@/components/compliance/ComplianceEthics.vue"),
    ComplianceAbout: () =>
      import("@/components/compliance/ComplianceAbout.vue"),
    ComplianceSpeak: () =>
      import("@/components/compliance/ComplianceSpeak.vue"),
    ComplianceForm: () => import("@/components/compliance/ComplianceForm.vue"),
  },
};
</script>
